export const REPORT_REASON = [
    { key: 'ad', value: '垃圾广告' },
    { key: 'sex', value: '色情' },
    { key: 'abuse', value: '辱骂' },
    { key: 'illegal', value: '违法信息' },
    { key: 'political', value: '政治敏感' },
    { key: 'nonage', value: '未成年人不良信息' },
    { key: 'other', value: '其他' }
];

